type CriteriaId = 'exampleCriteria'

export interface Criteria {
  id: CriteriaId
  check: (user: Commons.IUser, param: any) => boolean
}

type CriteriaInstance = { [id in CriteriaId]: any }

type CriteriaUse = CriteriaInstance & {
  $veto?: boolean
}

export interface Feature {
  name: string
  description: string
  owner: string
  criteria?: CriteriaUse[]
  enabled?: boolean
}

export const features: { [featureName in Features.FeatureName]: Feature } = {
  example: {
    name: 'Example feature',
    description: 'Unused feature demonstrating the use of fflip',
    owner: 'All of tech',
    criteria: [
      { exampleCriteria: true },
      // { exampleCriteria: false, $veto: true } // veto overrides results of other criteria checks],
    ],
    // enabled: false // sets the feature on or off for all users, required if `criteria` is not present
  },
  easyRescueModal: {
    name: 'Easy Rescue Modal',
    description: 'Enables easier rescue modal triggers for QA purposes',
    owner: 'psastras',
    enabled: false,
  },
  customFreeResponseQuestions: {
    name: 'Custom free resposne questions for tests',
    description: 'Enables button that allows teachers to author a free response question',
    owner: 'brett',
    enabled: false,
  },
  uiTestsSkipSubscriptionsCall: {
    name: 'UI Tests Cache Subscriptions Call',
    description: "Reduce calls to Chargebee by skipping the call to fetch a user's subscriptions",
    owner: 'allin',
    enabled: true,
  },
  productVisionDemoFlag: {
    name: 'Product Vision Demo',
    description: 'Enables a couple of buttons to prototype features',
    owner: 'jacob',
    enabled: false,
  },
  fullstoryFlag: {
    name: 'Fullstory',
    description: 'Enable Fullstory session capture',
    owner: 'alex.heitzmann',
    enabled: false,
  },
  questionOrderingFeatureFlag: {
    name: 'Enable question ordering functionality in teach flow',
    description: 'Instructor can arrange the question order for given LO',
    owner: 'Dimuthu',
    enabled: false,
  },
  nwpReviewCenterFlag: {
    name: 'Review Center for New Wiley Plus',
    description: 'Internal usage only - simulates Review Center UI for a NWP course',
    owner: 'allin',
    enabled: false,
  },
  scaffoldedQuestionsFlag: {
    name: 'Enable Scaffolded Questions',
    description:
      'Students can request a question scaffold that breaks a complex question into steps',
    owner: 'slevitt',
    enabled: false,
  },
  showResultsOfPastDueAssessmentsFlag: {
    name: 'Show results of past due assessments',
    description:
      'Students will able to view answers of past due assessments even they didnt attempt to do the test',
    owner: 'Malindu',
    enabled: false,
  },
  alertOnMultipleAttemptsVersionFlag: {
    name: 'Enable alerts for Multiple Attempts/Versions',
    description:
      'Show an alert when exact same content is repeated accross multiple alerts/versions ',
    owner: 'Hansamali',
    enabled: false,
  },
  copyCourseModalSkipBrokenAssignments: {
    name: 'Skip Broken Assignments',
    description:
      'Show a button to enable the user to skip the broken assignments when copying a course, rather that having the copy fail',
    owner: 'Alpaca',
    enabled: false,
  },
  contentOverlayFlag: {
    name: 'Hide content while it is loading or errored.',
    description:
      'Renders a Loading or Error component on top of content, hiding content as it loads in.',
    owner: 'Alpaca',
    enabled: false,
  },
  studentTransferSectionFlag: {
    name: 'Transfer a student between sections',
    description: 'Allow instructor to transfer a student between sections of the same course',
    owner: 'Alpaca',
    enabled: false,
  },
  // TODO - Remove this flag once the performance investigating of test taking experience summary
  //  report completed. YALA-564. This flag is created only for the performance investigation
  disableAssessmentLazyLoadForStudentFlag: {
    name: 'Disable assessment lazy loading for student',
    description:
      'Disable the assessment lazy loading for student. This should remove soon after testing is completed.',
    owner: 'Yala',
    enabled: false,
  },
  partialCreditCommentsFlag: {
    name: 'Enable partial credit commenting ',
    description: 'Allow instructor to give partial credit comment for student',
    owner: 'Yala',
    enabled: false,
  },
  multipleStudentResultsViewFlag: {
    name: 'Enable bulk partial credit option',
    description: 'Allows instructor to award bulk partial credit',
    owner: 'YALA',
    enabled: false,
  },
  homeworkAssessmentLabelFlag: {
    name: 'Enable homework as an assessment label',
    description:
      'Allow instructors to label an assessment as homework and show it as a category in roll-ups',
    owner: 'Alpaca',
    enabled: false,
  },
  assignmentBulkDeleteFlag: {
    name: 'Enable bulk deletion of assignments',
    description: 'Allow instructors to delete assessment/assignment as bulk.',
    owner: 'YALA',
    enabled: false,
  },
  assignmentBulkEditFlag: {
    name: 'Enable bulk editing of assignments',
    description: 'Allow instructors to edit start/end date as bulk.',
    owner: 'YALA',
    enabled: false,
  },
  studentAgencyFlag: {
    name: 'Student Agency',
    description: 'Allow students to pick their prereqs, skip them, change LO, etc.',
    owner: 'ALPACA',
    enabled: false,
  },
  bulkActionFollowUpsFlag: {
    name: 'Enable bulk action followups',
    description:
      'Enhancements on the bulk course/section outline relates to delete and edit actions for internal and external users',
    owner: 'YALA',
    enabled: false,
  },
  upgradeAssessmentSettingFlag: {
    name: 'Allow instructors to access upgraded assessment settings window ',
    description: 'Enhancements on the assessment setting page ',
    owner: 'YALA',
    enabled: false,
  },
  useWebcamPOCFlag: {
    name: 'Enable use webcam POC',
    description: 'POC for use webcam feature on the show student analysis',
    owner: 'YALA',
    enabled: false,
  },
  studentAgencyM2Flag: {
    name: 'Student Agency M2 (Switch Topic)',
    description: 'Allow students to switch topics in multitopic assignments',
    owner: 'ALPACA',
    enabled: false,
  },
  quizFlowQLFlag: {
    name: 'GraphQL-ify quiz flow',
    description: 'Redesign quiz flow and move to graphql',
    owner: 'ALPACA',
    enabled: false,
  },
  improveInstructorJoinM2Flag: {
    name: 'Instructor join M2',
    description:
      'Improvements to the current workflow for adding co-instructors to courses/sections.',
    owner: 'YALA',
    enabled: false,
  },
  upgradeAssessmentSettingM2Flag: {
    name: 'Allow instructors to access upgraded assessment settings new window with manual option ',
    description: 'Enhancements on the assessment setting page (Add manual option property) ',
    owner: 'YALA',
    enabled: false,
  },
  agencyImprovementFlag: {
    name: 'Allow Instructors to create or update the Agency setting',
    description:
      'This feature is to give instructors the ability to set agency settings for the students when an adaptive path is creating or updating',
    owner: 'YALA',
    enabled: false,
  },
  studentBulkExtensionFlag: {
    name: 'Allow instructors to grant bulk extension dates for student',
    description:
      'This will allow instructors to grant bulk extension date/timer for selected group of assessments of a particular student',
    owner: 'YALA',
    enabled: false,
  },
  inclusiveAccessFlag: {
    name: 'Allow admins to OPT IN and OPT OUT students form IA',
    description:
      'This will allow admins to be able to enforce a paywall for any student who has opted out of the IA program given a list provided by the IA program',
    owner: 'YALA',
    enabled: false,
  },
  assignmentLOsLengthEstimateFlag: {
    name: 'Enhanced assignment learning objective length estimate',
    description:
      'This will introduce a revamped endpoint for accurately estimating the length of learning objectives in assignments',
    owner: 'KUMANA',
    enabled: false,
  },
  courseAgencySettingsFlag: {
    name: 'Enable Agency and instruct first settings in course level',
    description: 'This will enable agency and instruct first setting in course level',
    owner: 'KUMANA',
    enabled: false,
  },
  configurableMasteryThresholdFlag: {
    name: 'Enable Configurable Mastery Threshold',
    description: 'This will enable configurable mastery threshold',
    owner: 'KUMANA',
    enabled: false,
  },
}
